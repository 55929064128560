.pagination {
  display        : flex;
  justify-content: space-between;
  list-style     : none;
  cursor         : pointer;
  margin-top     : 100px;
}

.pagination a {
  padding      : 10px;
  border-radius: 5px;
  border       : 1px solid #2c98f0;
  color        : #2c98f0;
  margin       : 8px;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color     : #fff;
  background: #2c98f0;
}

.pagination__link--disabled a {
  color : rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}