@import './components/home.scss';

.selectRed span {
  color: red;
}

h1 {
  font-family: Helvetica;
}

label {
  font-family: Helvetica;
}
